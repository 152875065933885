import { Box, VStack, Heading, Text, Button } from "@chakra-ui/react";
import { Link } from "gatsby";
import React from "react";

const NoPromotion = () => {
  return (
    <Box
      px="20px"
      bg="linear-gradient(180deg, #FFF 0%, #FAF3EC 41.15%, #D28C49 100%);"
      h="340px"
      w="inherit"
      py="64px"
    >
      <VStack
        h="100%"
        justifyContent="center"
        textAlign="center"
        maxW="1280px"
        margin="0 auto"
        px="20px"
      >
        <Heading fontSize="30px">No Active Promotions at the Moment</Heading>
        <Text fontSize="20px" fontWeight="500" pb="40px">
          As of now, we’re in a phase without ongoing promotions. Keep an eye on
          our future announcements for special deals and offers. In the
          meantime, our product catalog is always available to you.
        </Text>
        <Link to="/shop">
          <Button variant="outline">Browse Our Webshop</Button>
        </Link>
      </VStack>
    </Box>
  );
};

export default NoPromotion;

import { graphql, useStaticQuery } from 'gatsby';

export const promoSection = () => {
	const data = useStaticQuery(graphql`
		query MyQuery {
			wpPage(databaseId: { eq: 59547 }) {
				title
				promo {
					ispromoactive
					promorepeater {
						title
						subtitle
						additionaltext
						ispharma
						products {
							sku
						}
					}
				}
			}
		}
	`);
	return data.wpPage.promo;
};

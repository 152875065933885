import {
	Box,
	Button,
	Heading,
	Image,
	Link,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';

import React from 'react';
import { promoSection } from '../../queries/promoSection';
import useAuth from '../useAuth';

const LoginPromoBanner = () => {
	const { loggedIn, isUnlicensedUser, isDefaultCustomer } = useAuth();
	const promoData = promoSection();

	return (
		<>
			{promoData.promorepeater.map((promo: any, index: number) => {
				const isPharma = promo.ispharma;

				if (!loggedIn && isPharma) {
					return (
						<Box
							key={index}
							backgroundImage='/bg.webp'
							backgroundRepeat='no-repeat'
							backgroundSize='cover'>
							<Stack
								px={{ base: 3, md: 0 }}
								flexDir={{ base: 'column', lg: 'row' }}
								maxW='1280px'
								mx='auto'
								spacing={0}
								alignItems='center'
								pt={{ base: 16, md: 0 }}>
								<VStack alignItems={{ base: 'center', md: 'flex-start' }}>
									<Text
										color='#262626'
										fontSize={{ base: '3xl', md: '4xl' }}
										fontWeight='700'
										pb={3}>
										Unlock All Offers:{' '}
									</Text>
									<Text
										fontSize='16px'
										color='#262626'
										pb={10}
										textAlign='center'>
										Ensure you're{' '}
										<Text
											as='span'
											color='#262626'
											fontWeight={700}>
											logged in
										</Text>{' '}
										to view the complete range of our promotions
									</Text>
									<Link href='/sign-in'>
										<Button
											color='#000'
											variant='ghost'
											height='48px'
											fontSize='18px'
											fontWeight='600'
											bg='transparent'
											border='1px solid #000'
											borderRadius='1000px'
											_hover={{ bg: '#000', color: '#fff' }}
											width='230px'>
											Log In
										</Button>
									</Link>
								</VStack>
								<Image
									rel='preload'
									src='/woman.webp'
									alt='Grab our exclusive special offers on JUVEDERM®, RESTYLANE®, and more.'
								/>
							</Stack>
						</Box>
					);
				}

				if ((isUnlicensedUser || isDefaultCustomer) && isPharma) {
					return (
						<VStack
							key={index}
							justifyContent='center'
							w={{ base: '100%', md: '640px' }}
							h={{ base: '100%', md: '200px' }}
							mx='auto'
							my={20}
							backgroundImage={{
								base: '/dm-banner-mob.webp',
								md: '/dm-banner-back.webp',
							}}
							backgroundSize='contain'
							bgPos={'center'}
							bgRepeat='no-repeat'
							color='#232323'>
							<Box
								w={{ base: '220px', md: '100%' }}
								m={{ base: '12px', md: 0 }}
								textAlign='center'>
								<Text
									alignSelf='auto!important'
									fontSize='md'
									fontWeight={400}
									lineHeight='150%'>
									Don't miss out on our full offerings and deals.
								</Text>
								<Heading
									mb={4}
									fontSize='2xl!important'
									fontWeight={700}
									lineHeight='133%'>
									Upgrade your license to view them.
								</Heading>
								<Link
									href='https://talktodoctormedica.youcanbook.me/'
									target='_blank'
									w={{ base: '220px', md: '100%' }}>
									<Button
										fontSize='sm'
										w={{ base: '220px', md: '236px' }}
										fontWeight={700}
										color='#120B0C'
										variant='outline'
										textTransform={'uppercase'}>
										Upgrade Your Licence
									</Button>
								</Link>
							</Box>
						</VStack>
					);
				}

				return null; // If no conditions are met, return null for this iteration
			})}
		</>
	);
};

export default LoginPromoBanner;

import * as React from "react";
import { Box, HStack, Heading, VStack } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import Layout from "../components/Base/Layout";
import { ProductBreadcrumb } from "../components/Shop/ProductBreadcrumbs";
import { SEO } from "../components/Base/Seo";
import { promoSection } from "../queries/promoSection";
import NoPromotion from "../components/NoPromotion";
import ActivePromotions from "../components/ActivePromotions";
import Newsletter from "../components/Footer/Newsletter";

const Promotions: FunctionComponent = () => {
  const promoData = promoSection();
  const isPromotionActive = promoData.ispromoactive;

  return (
    <>
      <Layout>
        <Box>
          <Box maxW="1280px" margin="0 auto" px={{ base: "0px", xl: "20px" }}>
            <Box bg="white" display="flex" padding="24px 20px">
              <ProductBreadcrumb home="Home" promotions="Promotions" />
            </Box>

            <HStack
              px="20px"
              justifyContent="center"
              flexFlow={{ base: "column-reverse", md: "row" }}
            >
              <VStack alignItems="flex-start" width="100%">
                <Heading
                  as="h1"
                  lineHeight={{base: "30px", md: "54px"}}
                  fontSize={{ base: "2xl !important", md: "4xl !important" }}
                >
                  <span style={{ color: "#D28C49" }}>Dynamic Sales:</span>{" "}
                  Premium Aesthetic Products, Lower Costs
                </Heading>
              </VStack>
            </HStack>
          </Box>

          {isPromotionActive ? <ActivePromotions /> : <NoPromotion />}

          <Box px="20px" py="40px" maxW="1280px" m="0 auto">
            <Newsletter />
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export const Head = () => (
  <SEO
    title="Promotion List | DoctorMedica"
    description="See our discounted product selection and browse for your favourite supplies quickly and easily."
  />
);

export default Promotions;

import { graphql, useStaticQuery } from 'gatsby';

export const fetchAllProducts = () => {
	const data = useStaticQuery(graphql`
		fragment ProductImage on WpMediaItem {
			sourceUrl
			altText
		}

		fragment ProductPricing on WpSimpleProduct {
			price
			regularPrice
			onSale
			salePrice
			tierPrice {
				tierPrice {
					price
					price2
					price3
					price4
				}
			}
		}

		fragment ProductDetails on WpSimpleProduct {
			id
			name
			slug
			description
			databaseId
			sku
			stockStatus
			...ProductPricing
		}

		fragment ProductPromotions on WpSimpleProduct {
			promotions {
				onPromotion
				additionalTag
			}
		}

		fragment ProductTags on WpSimpleProduct {
			productTags {
				brandName: nodes {
					name
					id
					slug
				}
			}
		}

		fragment ProductCategories on WpSimpleProduct {
			productCategories {
				cat: nodes {
					name
					id
					slug
				}
			}
		}

		fragment RelatedProducts on WpSimpleProduct {
			related {
				relatedProducts: nodes {
					name
					slug
					databaseId
					sku
					description
					image {
						...ProductImage
					}
					...ProductDetails
				}
			}
		}

		query {
			allWpSimpleProduct {
				totalCount
				nodes {
					...ProductDetails
					image {
						...ProductImage
					}
					...ProductPromotions
					...ProductTags
					...ProductCategories
					...RelatedProducts
				}
			}
		}
	`);

	return data.allWpSimpleProduct.nodes;
};
